import React, { useEffect, useState } from "react";
import PersonForm from "./Form";
import FormInfo from "./FormInfo";

function App() {
  const [healthStatus, setHealthStatus] = useState('Loading...');

  useEffect(() => {
    const fetchHealthStatus = async () => {
      try {
        const response = await fetch('https://ca-qrin.livelycliff-04026f55.westeurope.azurecontainerapps.io/health');
        if (response.ok) {
          const data = await response.text();
          setHealthStatus(data);
        } else {
          setHealthStatus('Service is down');
        }
      } catch (error) {
        setHealthStatus('Error fetching health status');
      }
    };

    // Call the health check when the component mounts
    fetchHealthStatus();

    // Optionally, you can add a cleanup or dependency array
    // to control when this useEffect runs.
  }, []); // Empty dependency array ensures this runs only once when the component mounts


  useEffect(() => {}, [])
  return (
    <div>
      <div>
        <header>
          <div class="container">
            <h1 style={{color: 'white'}}>Hormonstudie</h1>
          </div>
        </header>
        <div class="container" id="main">
          <div class="portfolio-item">
            <FormInfo />
            <PersonForm />
            <p style={{fontSize: '10px'}}>  Service status: {healthStatus}</p>
          </div>

        </div>

        <footer>
          <p>Kontakt: karin.pola@med.lu.se</p>
        </footer>
      </div>
    </div>
  );
}

export default App;
