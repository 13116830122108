import React from "react";

const FormInfo = () => {
  return (
    <div>
      <h1>Deltagare sökes till hormonstudie med magnetkamera</h1>
      <p>
        Är du intresserad av att bidra till vetenskapen och få en inblick i hur
        ditt hjärta fungerar? Vi söker deltagare till en spännande hormonstudie
        där vi använder magnetkamera (MRI) för att undersöka hjärtats struktur
        och funktion i relation till hormonella förändringar.
      </p>

      <h2>Syfte med studien</h2>
      <p>
        Studien syftar till att förstå hur hormonella förändringar påverkar
        hjärtat och dess funktioner. Genom att använda avancerad bildteknik som
        magnetkamera kan vi få detaljerade bilder av hjärtat och analysera hur
        olika hormoner påverkar dess struktur och aktivitet.
      </p>

      <h2>Vad innebär deltagandet?</h2>
      <p>
        Som deltagare i studien kommer du att genomgå en eller flera
        MRI-skanningar. Dessa skanningar är icke-invasiva och helt smärtfria.
        Under skanningen kommer du att ligga stilla i en MRI-maskin medan vi tar
        bilder av ditt hjärta. Studien kommer också att inkludera blodprov för
        att mäta hormonnivåer och eventuellt några kardiovaskulära tester.
      </p>

      <h2>Vem kan delta?</h2>
      <p>
        Vi söker friska vuxna deltagare i åldern 18-65 år. Du behöver inte ha
        någon tidigare erfarenhet av liknande studier. Det är viktigt att du
        inte har några metallföremål i kroppen, såsom pacemaker eller
        metallimplantat, eftersom dessa kan påverka MRI-skanningen.
      </p>

      <h2>Fördelar med att delta</h2>
      <ul>
        <li>
          <strong>Bidra till forskning</strong>: Ditt deltagande hjälper
          forskare att förstå viktiga samband mellan hormoner och hjärtfunktion.
        </li>
        <li>
          <strong>Gratis hälsoundersökning</strong>: Du får en detaljerad bild
          av ditt hjärta, vilket kan vara av intresse för din personliga hälsa.
        </li>
        <li>
          <strong>Ersättning</strong>: Alla deltagare kommer att kompenseras för
          sin tid och eventuella resekostnader.
        </li>
      </ul>

      <h2>Hur anmäler du dig?</h2>
      <p>
        Om du är intresserad av att delta eller vill ha mer information,
        vänligen kontakta oss på{" "}
        <a href="mailto:karin.pola@med.lu.se">karin.pola@med.lu.se</a>. Vi ser
        fram emot att höra från dig och tackar på förhand för ditt bidrag till
        denna viktiga forskning.
      </p>
    </div>
  );
};
export default FormInfo;
