import React, { useState } from "react";
import {
  TextField,
  PrimaryButton,
  Stack,
  Text,
  Spinner,
} from "@fluentui/react";

const PersonForm = () => {
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    Age: "",
    PhoneNumber: "",
    Email: "",
  });

  const [status, setStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      const response = await fetch(
        "https://ca-qrin.livelycliff-04026f55.westeurope.azurecontainerapps.io/SendSubmission",
        {
          method: "POST",
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        console.log("Form data submitted successfully:");
        setErrorMessage("");
        setStatus(
          "Vi har mottagit din information och kommer att hantera den så snart som möjligt. Tack för ditt tålamod!"
        );
        setIsLoading(false);
        setTimeout(() => {
          setStatus("");
        }, 5000);
      } else {
        console.error("Error submitting form data:");
        setErrorMessage("Något gick fel, återkom gärna senare...");

        setTimeout(() => {
          setStatus("");
          setErrorMessage("");
        }, 5000);
      }
    } catch (error) {
      console.error("Network error:", error);
      setErrorMessage("Något gick fel, återkom gärna senare...");
      setTimeout(() => {
        setStatus("");
        setErrorMessage("");
      }, 5000);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Stack
          tokens={{ childrenGap: 15 }}
        >
          <TextField
            label="Förnamn"
            name="FirstName"
            value={formData.FirstName}
            onChange={handleChange}
            required
          />
          <TextField
            label="Efternamn"
            name="LastName"
            value={formData.LastName}
            onChange={handleChange}
            required
          />
          <TextField
            label="Ålder"
            name="Age"
            value={formData.Age}
            onChange={handleChange}
            required
          />
          <TextField
            label="Telefonnummer"
            name="PhoneNumber"
            value={formData.PhoneNumber}
            onChange={handleChange}
            required
          />
          <TextField
            label="Email"
            name="Email"
            type="email"
            value={formData.Email}
            onChange={handleChange}
            required
          />
          <PrimaryButton type="submit" text="Submit" disabled={isLoading} />
        </Stack>
      </form>

      {isLoading && (
        <div style={{ margin: '16px'}}>
          <Spinner />
        </div>
      )}
      {status !== "" && (
        <Text style={{ color: "green", textWrap: "wrap" }}>{status}</Text>
      )}
      {errorMessage !== "" && (
        <Text style={{ color: "red" }}>{errorMessage}</Text>
      )}
    </div>
  );
};

export default PersonForm;
